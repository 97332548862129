const useCurrencyFormatter = () => {
  const formatCurrency = (amount?: number, currency?: string, showCents?: boolean) => {
    const DEFAULT_LANGUAGE = 'de-DE';
    const DEFAULT_CURRENCY_SYMBOL = '€';

    const options = {
      minimumFractionDigits: showCents ? 2 : 0,
      maximumFractionDigits: showCents ? 2 : 0,
    };

    if (!currency) return 'Incorrect value';

    if (!amount) return 'Incorrect value';

    const formattedCurrency = new Intl.NumberFormat(DEFAULT_LANGUAGE, options).format(amount);

    return `${DEFAULT_CURRENCY_SYMBOL}${formattedCurrency}`;
  };

  return {
    formatCurrency,
  };
};

export default useCurrencyFormatter;
