import styled, { css } from 'styled-components';
import Button from 'src/presentation/components/ui/Button/Button';
import { TripPrice } from 'src/domain/Schema/model/apiTypes';
import useScrollToElement from 'src/toolkit/utils/useScrollToElement';
import ElementId from 'src/integration/config/ElementId';
import Price from 'src/presentation/components/Basket/elements/Price';
import { useTranslation } from 'react-i18next';
import React from 'react';
import useCurrencyFormatter from 'src/toolkit/utils/useCurrencyFormatter';
import useInquiryLinkCreator from 'src/toolkit/hooks/useInquiryLinkCreator';
import ButtonLink from 'src/presentation/components/ui/ButtonLink/ButtonLink';
import useEventLogger from 'src/toolkit/hooks/useEventLogger';

interface ComponentProps {
  price: TripPrice;
}

const ButtonArea = styled.div`
  ${() => css`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    grid-gap: 24px;
  `}
`;

const StyledButton = styled(Button)`
  ${() => css`
    width: 100%;
    flex: 1 0 0;
  `}
`;

const StyledPrice = styled(Price)`
  ${() => css`
    width: 100%;
    text-align: right;
    display: flex;
    align-items: flex-end;
  `}
`;

const BasketPdpFooter = ({ price }: ComponentProps) => {
  const { scrollToElement } = useScrollToElement();
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencyFormatter();
  const { inquiryLink } = useInquiryLinkCreator();
  const { logSummaryAvailabilityButtonClick } = useEventLogger();

  const handleClick = () => {
    scrollToElement(ElementId.availabilityList);
    logSummaryAvailabilityButtonClick();
  };

  return (
    <>
      <StyledPrice price={formatCurrency(price.amount, price.currency)} />

      <ButtonArea>
        <ButtonLink styleType={'secondary'} href={inquiryLink}>
          {t('summary_form.button.enquiry')}
        </ButtonLink>

        <StyledButton styleType={'primary'} onClick={handleClick}>
          {t('summary_form.button.check_availability')}
        </StyledButton>
      </ButtonArea>
    </>
  );
};

export default BasketPdpFooter;
