import React, { FunctionComponent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Icon from 'src/presentation/components/ui/Icon/Icon';
import IconArrowDown from 'src/presentation/components/ui/Icon/IconArrowDown';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import useDropdown from 'src/toolkit/hooks/useDropdown';
import ErrorText from 'src/presentation/components/FormFields/ErrorText';

interface ComponentProps extends Component {
  icon?: FunctionComponent<SimpleObject>;
  text: string;
  disabled?: boolean;
  disableOptionClick?: boolean;
  maxHeight?: number;
  children: ReactNode;
  errors?: string;
}

const Root = styled.div`
  ${() => css`
    width: 100%;
  `}
`;

const DropdownWrapper = styled.div<{ $disabled?: boolean }>`
  ${({ $disabled }) => css`
    width: 100%;
    position: relative;
    flex-shrink: 1;
    flex-grow: 1;
    user-select: none;

    ${$disabled &&
    css`
      cursor: not-allowed;
      color: ${colors.neutral50};
    `}
  `}
`;

const DropdownButton = styled.div<{ $disabled?: boolean; $error?: boolean }>`
  ${({ $disabled, $error }) => css`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    padding: 14px 16px;
    border: 2px solid ${colors.neutral80};

    ${$error &&
    css`
      border: 2px solid ${colors.error};
    `}

    ${$disabled &&
    css`
      pointer-events: none;
      background: ${colors.neutral90};
    `}
  `}
`;

const DropdownMenu = styled.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => css`
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    box-sizing: border-box;
    height: fit-content;
    width: 100%;
    border: 2px solid ${colors.neutral80};
    border-radius: 10px;
    background-color: ${colors.neutral100};
    padding: 16px;
    margin: 0;
    z-index: 1;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);

    ${!$isOpen &&
    css`
      display: none;
    `}
  `}
`;

const AlignLeft = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    gap: 8px;
  `}
`;

const Wrapper = styled.div<{ $maxHeight?: number }>`
  ${({ $maxHeight }) => css`
    ${$maxHeight &&
    css`
      max-height: ${$maxHeight}px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: ${colors.neutral90};
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${colors.neutral80};
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${colors.neutral50};
      }

      scrollbar-width: auto;
      scrollbar-color: ${colors.neutral80} ${colors.neutral90};
    `}
  `}
`;

const Dropdown = ({ icon, text, children, disableOptionClick, maxHeight, disabled, errors }: ComponentProps) => {
  const { isOpen, dropdownRef, contentRef, toggleDropdown } = useDropdown(disableOptionClick);

  return (
    <Root>
      <DropdownWrapper ref={dropdownRef} $disabled={disabled}>
        <DropdownButton onClick={toggleDropdown} data-testid={'dropdown-button'} $disabled={disabled} $error={!!errors}>
          <AlignLeft>
            {icon && <Icon icon={icon} size={24} />}
            <Paragraph color={disabled ? 'neutral50' : 'neutral10'} large>
              {text}
            </Paragraph>
          </AlignLeft>
          <IconArrowDown size={24} isOpen={isOpen} />
        </DropdownButton>
        <DropdownMenu $isOpen={isOpen} data-testid={'dropdown-menu'} aria-expanded={isOpen}>
          <Wrapper $maxHeight={maxHeight} ref={contentRef}>
            {children}
          </Wrapper>
        </DropdownMenu>
      </DropdownWrapper>
      {errors && <ErrorText error={errors} />}
    </Root>
  );
};

export default Dropdown;
