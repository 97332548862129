import React from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/integration/styles/colors';
import Button from 'src/presentation/components/ui/Button/Button';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import AdditionalInfoBlock from 'src/presentation/components/AvailabilityComponent/elements/AdditionalInfoBlock';
import useDateTimeFormatter from 'src/toolkit/utils/useDateTimeFormatter';
import breakpoints from 'src/integration/styles/breakpoints';
import useViewport from 'src/toolkit/utils/useViewport';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  isAvailable?: boolean;
  isAdditionalInfoEnabled?: boolean;
  startDate?: string;
  endDate?: string;
  price?: string;
  onClick: () => void;
}

const Root = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid ${colors.neutral90};

    ${disabled &&
    css`
      background: ${colors.neutral96};
    `}
  `}
`;

const Package = styled.div<{ disabled?: boolean }>`
  ${() => css`
    display: flex;
    align-items: center;
    grid-gap: 24px;
    align-self: stretch;
    justify-content: space-between;
    padding: 16px;

    ${breakpoints.desktop`
      padding: 16px 24px;
    `}
  `}
`;

const Column = styled.div`
  ${() => css``}
`;

const ParagraphWrapper = styled.div``;

const StyledParagraph = styled(Paragraph)<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    ${breakpoints.mobile`
      max-width: 90px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}

    ${disabled &&
    css`
      color: ${colors.neutral50};
    `}
  `}
`;

const CustomStyledParagraph = styled(StyledParagraph)`
  ${() => css`
    line-height: 16px;

    ${breakpoints.desktop`
      line-height: 18px;
    `}
  `}
`;

const AlignLeft = styled.div`
  ${() => css`
    align-self: flex-start;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    ${breakpoints.desktop`
      gap: 4px;
    `}
  `}
`;

const AlignRight = styled.div`
  ${() => css`
    display: flex;
    flex-wrap: wrap;
    grid-gap: 16px;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 1;

    ${breakpoints.desktop`
      grid-gap: 24px;  
    `}
  `}
`;

const Price = styled.p<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    font-family: ${theme.fonts.fontFamily};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.15px;

    ${disabled &&
    css`
      color: ${colors.neutral50};
    `}

    ${breakpoints.desktop`
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.28px;
    `}
  `}
`;

const PackageAvailability = ({
  isAvailable = false,
  isAdditionalInfoEnabled,
  startDate,
  endDate,
  price,
  onClick,
}: ComponentProps) => {
  const { getWeekday, formatDate, formatDateCompact } = useDateTimeFormatter();
  const { isMobile, isDesktop } = useViewport();
  const { t } = useTranslation();

  return (
    <Root disabled={!isAvailable}>
      <Package>
        <AlignLeft>
          <ParagraphWrapper>
            <StyledParagraph small={isMobile} disabled={!isAvailable}>
              {getWeekday(startDate)}
            </StyledParagraph>
            <CustomStyledParagraph large={isDesktop} semiBold disabled={!isAvailable}>
              {isMobile ? formatDateCompact(startDate) : formatDate(startDate)}
            </CustomStyledParagraph>
          </ParagraphWrapper>

          <ParagraphWrapper>
            <StyledParagraph small={isMobile} disabled={!isAvailable}>
              {getWeekday(endDate)}
            </StyledParagraph>
            <CustomStyledParagraph large={isDesktop} semiBold disabled={!isAvailable}>
              {isMobile ? formatDateCompact(endDate) : formatDate(endDate)}
            </CustomStyledParagraph>
          </ParagraphWrapper>
        </AlignLeft>
        <AlignRight>
          <Column>
            <StyledParagraph small={isMobile} disabled={!isAvailable}>
              {t('availability_form.price.from')}
            </StyledParagraph>
            <Price disabled={!isAvailable}>{price}</Price>
            <StyledParagraph small={isMobile} disabled={!isAvailable}>
              {t('availability_form.price.per_person')}
            </StyledParagraph>
          </Column>
          <Column>
            <Button small={isMobile} disabled={!isAvailable} styleType={'primary'} onClick={onClick}>
              {isAvailable ? t('availability_form.button.choose') : t('availability_form.button.sold_out')}
            </Button>
          </Column>
        </AlignRight>
      </Package>

      {isAdditionalInfoEnabled && <AdditionalInfoBlock />}
    </Root>
  );
};

export default PackageAvailability;
