import React, { ReactNode } from 'react';
import WhiteBox from 'src/presentation/components/ui/WhiteBox/WhiteBox';
import styled, { css } from 'styled-components';
import Separator from 'src/presentation/components/ui/Separator/Separator';
import Details from 'src/presentation/components/Basket/elements/Details';
import { PackageDetailsResponseType } from 'src/domain/Schema/model/apiTypes';
import useTextBuilders from 'src/toolkit/utils/useTextBuilders';
import {
  iconBriefcase,
  iconCalendar,
  iconChat,
  iconGrading,
  iconMarker,
  iconStar,
  iconStatistic,
  iconUsers,
} from 'src/integration/assets/AssetsProvider';
import { useTranslation } from 'react-i18next';
import TravelDateBlock from 'src/presentation/components/Basket/elements/TravelDateBlock';
import breakpoints from 'src/integration/styles/breakpoints';
import ButtonLink from 'src/presentation/components/ui/ButtonLink/ButtonLink';

interface ComponentProps {
  details?: PackageDetailsResponseType;
  footer?: ReactNode;
  startDate?: string;
  endDate?: string;
  displayThingsToKnowLink?: boolean;
}

const StyledDetails = styled(Details)`
  ${() => css`
    margin: 8px 0;

    ${breakpoints.desktop`
      margin: 12px 0;
    `}
  `}
`;

const StyledTravelDateBlock = styled(TravelDateBlock)`
  ${() => css`
    margin: 24px 0;
  `}
`;

const Basket = ({ details, footer, startDate, endDate, displayThingsToKnowLink }: ComponentProps) => {
  const { buildDirectionWithCountryText } = useTextBuilders();
  const { t } = useTranslation();

  return (
    <WhiteBox title={details?.title || ''} vPadding={24} hPaddingDesktop={24}>
      {startDate && endDate && <StyledTravelDateBlock startDate={startDate} endDate={endDate} />}

      <StyledDetails icon={iconCalendar} text={`${details?.duration} ${t('summary_form.text.days')}`} />
      <StyledDetails
        icon={iconMarker}
        text={buildDirectionWithCountryText(
          details?.startLocation?.cityName,
          details?.startLocation?.countryName,
          details?.endLocation?.cityName,
          details?.endLocation?.countryName,
        )}
      />
      <StyledDetails icon={iconChat} text={t('summary_form.text.in_english')} />
      <StyledDetails icon={iconUsers} text={`${t('summary_form.text.group_size')} ${details?.groupSize}`} />
      <StyledDetails icon={iconStar} text={`${t('summary_form.text.service_level')} ${details?.serviceLevel?.label}`}>
        {details?.serviceLevel?.description}
      </StyledDetails>
      <StyledDetails
        icon={iconGrading}
        text={`${t('summary_form.text.physical_grading')} ${details?.physicalGrading?.label}`}
      >
        {details?.physicalGrading?.description}
      </StyledDetails>
      <StyledDetails
        icon={iconStatistic}
        text={`${t('summary_form.text.minimum_age')} ${t('summary_form.text.minimum_age.description')}`}
      >
        {t('summary_form.text.minimum_age.description')}
      </StyledDetails>
      {displayThingsToKnowLink && details?.pdfDocumentUrl && (
        <StyledDetails
          icon={iconBriefcase}
          text={
            <ButtonLink href={details.pdfDocumentUrl} large target={'_blank'}>
              {t('summary_form.text.things_to_know')}
            </ButtonLink>
          }
        />
      )}

      <Separator space={24} />

      <>{footer}</>
    </WhiteBox>
  );
};

export default Basket;
