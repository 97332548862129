import React from 'react';
import Paragraph from 'src/presentation/components/ui/Paragraph/Paragraph';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

interface ComponentPros extends Component {
  price: string;
}

const Root = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
  `}
`;

const PriceBlock = styled.div`
  ${() => css`
    display: inline-flex;
    width: auto;
    align-items: center;
  `}
`;

const PriceElement = styled.span`
  ${({ theme }) => css`
    font-family: ${theme.fonts.fontFamily};
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.28px;
    padding: 0 7px;
  `}
`;

const StyledParagraph = styled(Paragraph)`
  ${() => css`
    display: inline;
    text-align: right;
  `}
`;

const Price = ({ price, className }: ComponentPros) => {
  const { t } = useTranslation();

  return (
    <Root className={className}>
      <PriceBlock>
        <Paragraph>{t('summary_form.price.from')}</Paragraph>
        <PriceElement>{price}</PriceElement>
        <Paragraph>{t('summary_form.price.per_person')}</Paragraph>
      </PriceBlock>
      <StyledParagraph>{t('summary_form.price.taxes_included')}</StyledParagraph>
    </Root>
  );
};

export default Price;
